import { Button, Container } from '@amzn/awsui-components-react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

import Layout from '../components/Layout';
import { UIPath } from '../paths';

function LandingContent() {
  return (
    <Box margin={{ bottom: 'l' }}>
      <div className='pasta-landing__header'>
        <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
          <Grid gridDefinition={[{ colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } }]}>
            <div className='pasta-landing__header-title'>
              <Box variant='h1' fontWeight='heavy' padding='n' fontSize='display-l' color='inherit'>
                Pasta Service
              </Box>
              <Box fontWeight='light' padding={{ bottom: 's' }} fontSize='display-l' color='inherit'>
                All the Pasta you could ever want
              </Box>
              <Box variant='p' fontWeight='light'>
                <span className='pasta-landing__header-sub-title'>
                  Pasta Service is a tool that catalogs the wonderful and diverse world of pasta!
                </span>
              </Box>
            </div>
            <div className='pasta-landing__header-cta'>
              <Container>
                <SpaceBetween size='xl'>
                  <Box variant='h2' padding='n'>
                    Get started
                  </Box>
                  <Box variant='p' padding='n'>
                    Get started by logging some of your favorite pastas!
                  </Box>
                  <Button href={UIPath.CreatePasta} variant='primary'>
                    Log Pasta
                  </Button>
                </SpaceBetween>
              </Container>
            </div>
          </Grid>
        </Box>
      </div>

      <Box padding={{ top: 's', horizontal: 's' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
            { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
          ]}
        >
          <SpaceBetween size='xxl'>
            <div>
              <Box variant='h1' tagOverride='h2' padding={{ bottom: 's', top: 'n' }}>
                How it works
              </Box>
              <Container>TODO</Container>
            </div>
            <div>
              <Box variant='h1' tagOverride='h2' padding={{ bottom: 's', top: 'n' }}>
                Benefits and features
              </Box>
              <Container>TODO</Container>
            </div>
          </SpaceBetween>
        </Grid>
      </Box>
    </Box>
  );
}

export default function Landing() {
  return <Layout disableContentPaddings content={<LandingContent />} />;
}
